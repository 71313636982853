import Auth from "@/Auth";
import axios, { AxiosInstance } from "axios";
import { BASE_URL } from "./consts";

const clientHttp: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + Auth.token()
    }
})

export default clientHttp