export enum AlertType {
    PRIMARY,
    SECONDARY,
    SUCCESS,
    DANGER,
    WARNING,
    INFO,
    LIGHT,
    DARK
}

export interface Alert {
    text: string
    type: AlertType
}