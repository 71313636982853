import User from "@/Interfaces/User";
import { State } from "@/Store";
import { GET_TOKEN_LOGIN } from "@/Store/actions";
import { DELETE_USER_STATE, SET_USER_STATE } from "@/Store/mutations";
import { Store } from "vuex";
import CookieHandler from "@/CookieHandler";
import { BASE_URL } from "@/Http/consts"
import axios from "axios";
import { useRouter } from "vue-router";

export default class Auth {

    public static verifyLocal(): User | null {
        const userData = atob(localStorage.getItem(btoa("user")) || "")
        if (!userData) {
            return null
        }
        return JSON.parse(userData)
    }

    public static userIsValid(store: Store<State>) {
        let user = Auth.user(store)
        if (!user) {
            user = Auth.verifyLocal()
        } 
        if (user) {
            axios.get(BASE_URL + "/token/validation", {
                headers: {
                    Authorization: "Bearer " + Auth.token(),
                    Accept: "application/json"
                }
            })
                .then(res => {
                    if (res.data) {
                        store.commit(SET_USER_STATE, user)
                    }
                    store.state.AppIsLoading = false
                })
                .catch(() => {
                    if (store.state.UserState.user) {
                        Auth.logout(store)
                    }
                    store.state.AppIsLoading = false
                })
        } else {
            store.state.AppIsLoading = false
        }
    }

    public static token(): string | null {
        return (Auth.verifyLocal()?.token as string)
    }

    public static user(store: Store<State>): User | null {
        const user = store.state.UserState.user
        return user
    }

    public static login(user: User, store: Store<State>) {
        store.dispatch(GET_TOKEN_LOGIN, user)
    }

    public static logout(store: Store<State>) {
        store.commit(DELETE_USER_STATE)
    }

    public static localRegister(user: User) {
        localStorage.setItem(btoa("user"), btoa(JSON.stringify(user)))
    }

    public static localLogout(user: User) {
        localStorage.removeItem(btoa("user"))
    }

}