import { Alert } from "@/Interfaces/Alert";
import { Module } from "vuex";
import { State } from "..";
import { ALERT } from "../mutations";

export interface AlertStateInterface {
    alert: Alert | null
}

export const AlertState: Module<AlertStateInterface, State> = {
    mutations:{
        [ALERT](state, alert: Alert) {
            state.alert = alert
            setTimeout(() => {
                state.alert = null
            }, 3000);
        }
    }
}