// USER MUTATIONS
export const SET_USER_STATE = "SET_USER_STATE"
export const DELETE_USER_STATE = "DELETE_USER_STATE"

// ALERT MUTATIONS
export const ALERT = "ALERT"

// LABELS MUTATIONS
export const SET_LABEL_CAN_FULL_FILL_LIST_STATE = "SET_LABEL_CAN_FULL_FILL_LIST_STATE"
export const SET_LABEL_CART_STATE = "SET_LABEL_CART_STATE"
export const ADD_LABEL_ON_ADD_TO_CART_STATE = "ADD_LABEL_ON_ADD_TO_CART_STATE"
export const REMOVE_LABEL_ON_ADD_TO_CART_STATE = "REMOVE_LABEL_ON_ADD_TO_CART_STATE"
export const LABEL_IS_LOADING = "LABEL_IS_LOADING"

// PRODUCTS MUTATIONS
export const SET_PRODUCTS_REFERENCE_LIST_STATE = "SET_PRODUCTS_REFERENCE_LIST_STATE"
export const SET_PRODUCT_REFERENCE_SELECTED_STATE = "SET_PRODUCT_REFERENCE_SELECTED_STATE"
export const PRODUCT_LIST_NEXT_PAGE = "PRODUCT_LIST_NEXT_PAGE"
export const PRODUCT_LIST_PREVIOUS_PAGE = "PRODUCT_LIST_PREVIOUS_PAGE"
export const PRODUCT_LIST_SET_PAGE = "PRODUCT_LIST_SET_PAGE"

// PANELS && POSITIONS MUTATIONS
export const SET_PANELS_LIST_STATE = "SET_PANELS_LIST_STATE"
export const SET_POSITIONS_LIST_STATE = "SET_POSITIONS_LIST_STATE"

// ORDERS
export const SET_ORDERS_TO_PRINT_LIST_STATE = "SET_ORDERS_TO_PRINT_LIST_STATE"
export const REMOVE_ORDER_TO_PRINT_LIST_STATE = "REMOVE_ORDER_TO_PRINT_LIST_STATE"
export const SET_ORDERS_WAS_PRINTED_LIST_STATE = "SET_ORDERS_WAS_PRINTED_LIST_STATE"
export const SET_ORDERS_TO_PRODUCT_LIST_STATE = "SET_ORDERS_TO_PRODUCT_LIST_STATE"
export const SET_ORDERS_WAS_IN_PRODUCTION_LIST_STATE = "SET_ORDERS_WAS_IN_PRODUCTION_LIST_STATE"
export const REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE = "REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE"

// SHOPEE
export const SET_IMPORTED_ORDERS_SHOPEE_LIST_STATE = "SET_IMPORTED_ORDERS_SHOPEE_LIST_STATE"
export const SET_ORDERS_ON_PRODUCTION_SHOPEE_LIST_STATE = "SET_ORDERS_ON_PRODUCTION_SHOPEE_LIST_STATE"

// ORDERS SHIPPING
export const SET_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE = "SET_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE"
export const ADD_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE = "ADD_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE"
export const DELETE_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE = "DELETE_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE"
export const SET_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE = "SET_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE"
export const ADD_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE = "ADD_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE"
export const DELETE_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE = "DELETE_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE"
export const SET_TO_PRINT_ORDERS_SHIPPING_LIST_STATE = "SET_TO_PRINT_ORDERS_SHIPPING_LIST_STATE"
export const ADD_TO_PRINT_ORDERS_SHIPPING_LIST_STATE = "ADD_TO_PRINT_ORDERS_SHIPPING_LIST_STATE"
export const DELETE_TO_PRINT_ORDERS_SHIPPING_LIST_STATE = "DELETE_TO_PRINT_ORDERS_SHIPPING_LIST_STATE"
export const UPDATE_AN_ORDERS_SHIPPING = "UPDATE_AN_ORDERS_SHIPPING"

// CUTTER PRODUCTION
export const SET_SHIPPINGS_TO_PRODUCT_LIST_STATE = "SET_SHIPPINGS_TO_PRODUCT_LIST_STATE"
export const SET_SHOPEE_PRODUCTION_LIST_STATE = "SET_SHOPEE_PRODUCTION_LIST_STATE"

