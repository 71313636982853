// USER ACTIONS
export const GET_TOKEN_LOGIN = "GET_TOKEN_LOGIN"


// LABELS ACTIONS
export const GET_LABELS_ON_CART = "GET_LABELS_ON_CART"
export const GET_LABELS_CAN_FULL_FILL_LIST = "GET_LABELS_CAN_FULL_FILL_LIST"
export const ADD_LABEL_FROM_ADD_TO_CART_ON_CART = "ADD_LABEL_FROM_ADD_TO_CART_ON_CART"
export const REMOVE_LABEL_FROM_CART = "REMOVE_LABEL_FROM_CART"
export const FULL_FILL_LABEL = "FULL_FILL_LABEL"

// PRODUCTS ACTIONS
export const GET_PRODUCTS_REFERENCE = "GET_PRODUCTS_REFERENCE"
export const SELECT_PRODUCT_REFERENCE = "SELECT_PRODUCT_REFERENCE"

// PANELS && POSITIONS ACTIONS
export const GET_PANELS_LIST = "GET_PANELS_LIST"
export const GET_POSITIONS_LIST = "GET_POSITIONS_LIST"
export const POST_PRODUCT_POSITION = "POST_PRODUCT_POSITION"

// ORDERS
export const GET_ORDERS_TO_PRINT = "GET_ORDERS_TO_PRINT"
export const SET_ORDER_PRINTED = "SET_ORDER_PRINTED"
export const GET_ORDERS_WAS_PRINTED = "GET_ORDERS_WAS_PRINTED"
export const GET_ORDERS_WAS_IN_PRODUCTION = "GET_ORDERS_WAS_IN_PRODUCTION"
export const GET_ORDERS_TO_PRODUCT = "GET_ORDERS_TO_PRODUCT"
export const PACK_AN_ORDER = "PACK_AN_ORDER"

// SHOPEE
export const SEND_FILE_TO_IMPORT_SHOPEE = "SEND_FILE_TO_IMPORT_SHOPEE"
export const GET_IMPORTED_ORDERS_SHOPEE = "GET_IMPORTED_ORDERS_SHOPEE"
export const GET_ORDERS_ON_PRODUCTION_SHOPEE = "GET_ORDERS_ON_PRODUCTION_SHOPEE"

// ORDERS SHIPPING
export const FLUSH_ALL_ORDERS_SHIPPING = "FLUSH_ALL_ORDERS_SHIPPING"
export const GET_ORDERS_SHIPPING_TO_PRODUCT = "GET_ORDERS_SHIPPING_TO_PRODUCT"
export const GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION = "GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION"
export const GET_ORDERS_SHIPPING_TO_PRINT = "GET_ORDERS_SHIPPING_TO_PRINT"
export const SET_ORDERS_SHIPPING_AS_IN_PRODUCTION = "SET_ORDERS_SHIPPING_AS_IN_PRODUCTION"
export const GET_ORDERS_FROM_ORDERS_SHIPPING = "GET_ORDERS_FROM_ORDERS_SHIPPING"
export const SET_ORDERS_SHIPPING_AS_PRINTED = "SET_ORDERS_SHIPPING_AS_PRINTED"
export const SET_ORDERS_SHIPPING_AS_PACKED = "SET_ORDERS_SHIPPING_AS_PACKED"

//CUTTER PRODUCTION
export const GET_PRODUCTS_TO_PRODUCT_FROM_SHOPEE = "GET_PRODUCTS_TO_PRODUCT_FROM_SHOPEE"
export const GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION_TO_CUTTER = "GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION_TO_CUTTER"
export const GET_CUTTERS_TO_PRODUCT_FROM_SHIPPINGS_WAS_IN_PRODUCTION = "GET_CUTTERS_TO_PRODUCT_FROM_SHIPPINGS_WAS_IN_PRODUCTION"