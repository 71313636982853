import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "offcanvas offcanvas-end",
  tabindex: "-1",
  id: "offcanvasNavbar",
  "aria-labelledby": "offcanvasNavbarLabel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OffcanvasHeader = _resolveComponent("OffcanvasHeader")!
  const _component_OffcanvasBody = _resolveComponent("OffcanvasBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_OffcanvasHeader),
    _createVNode(_component_OffcanvasBody)
  ]))
}